/* global fbq */
import React, { useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import axios from "axios"
import { inputStyle, submitButtonStyle } from "./Styles"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import { selectStyles } from "src/styles/select"
import {hashEmail} from "src/services/util/hash"

const CITIES = [
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "San Diego", label: "San Diego" },
  { value: "Other", label: "Other" },
]
 
const FormContainer = styled.div`
  line-height: 1.6em;
`

const Header = styled.h2`
  color: ${colors.royalBlue};
  margin-bottom: 2rem;
  line-height: 1.104;
  text-align: center;
`

const StyledForm = styled.form`
  text-align: center;
`

const InputContainerGroup = styled.div`
  display: flex;
  flex-direction: row;
`

const InputContainer = styled.div`
  margin-bottom: 24px;
  margin-right: 2%;
  text-align: left;
`

const InputContainerHalfWidth = styled.div`
  margin-bottom: 24px;
  width: 48%;
  margin-right: 2%;
  text-align: left;
`

const FormLabel = styled.label`
font-size: 20px;
`

const Submit = styled.button`
  ${submitButtonStyle}
`

const ErrorText = styled.p`
  text-align: center;
  padding-top: 17px;
`

const Input = styled.input`
  ${inputStyle}
`

const styledOptionStyles = () => {
  return {
    ...selectStyles(),
    control: (styles) => ({
      ...styles,
      borderRadius: "2px",
      border: `1px solid #ccc`,
      padding: "12px",
      boxShadow: "none",
      cursor: "pointer",
      minHeight: "unset",
      fontSize: "12px",
      lineHeight: "normal",
      background: "transparent",
      margin: "6px 0 4px",
      color: "#000",
      background: "#fafafa",
      ":hover": {
        borderColor: colors.midGrey,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "12px",
      lineHeight: "normal",
    }),
  }
}

const GetNotifiedFormHomePage = ({signup}) => {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [city, setCity] = useState(CITIES[0])
  const [message, setMessage] = useState("")
  // const [cityOptional, setCityOptional] = useState()

  const handleSubmit = async (event) => {
    event.preventDefault()

    const url = "/.netlify/functions/getNotified"

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      city: city.value,
    }

    let old_gtag_data = {
      event_category: "email subscribe",
      event_label: "waitlist",
      // firstName: firstName,
      // lastName: lastName,
      // email: email,
      city: city.value,
      send_to: "UA-185335499-1"
    }

    try{
      
      // gtag('event', 'sign_up', data);
      // console.log('subscribed gtag sent');
      if (typeof window.gtag !== 'undefined') {
         window.gtag('event', 'form_submit', old_gtag_data)
      }
    } catch{
      console.log('gtag event sign_up not sent')
    }

    try{
      fbq('track', 'Lead', {
        content_name: 'Email Signup',
        content_category: 'Newsletter',
        user_data: {
          em : hashEmail(data.email)
        }
      });
    } catch(e)
    {
      console.log(e)
    }
    try {
      const response = await axios.post(url, data)
      if (response.status === 200) {
        setMessage("Submitted!")
        setFirstName("")
        setLastName("")
        setEmail("")
        setCity(null)
        // setCityOptional("")
      }
    } catch {
      setMessage("Error")
    } finally {
      setTimeout(() => {
        setMessage("")
      }, 6000);
    }
  }

  return (
    <FormContainer signup={signup ? 1 : 0}>
      {!signup && <Header>Get notified!</Header>}
      <StyledForm onSubmit={handleSubmit}>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel>First name *</FormLabel>
            <Input
              type="text"
              name="contact[firstName]"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel>Last name</FormLabel>
            <Input
              type="text"
              name="contact[lastName]"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        <InputContainer>
          <FormLabel>Email *</FormLabel>
          <Input
            type="text"
            name="contact[email]"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </InputContainer>
        <InputContainer>
          <FormLabel>City *</FormLabel>
          <Select
            onChange={(e) => setCity(e)}
            options={CITIES}
            isSearchable={false}
            value={city}
            styles={styledOptionStyles()}
          />
        </InputContainer>
        {/* {signup && (
          <InputContainer>
            <FormLabel>City (if you selected other)</FormLabel>
            <Input
              type="text"
              name="contact[city]"
              onChange={(e) => setCityOptional(e.target.value)}
              value={cityOptional}
            />
          </InputContainer>
        )} */}
        <Submit type="submit">{message === "" ? "Send" : message}</Submit>
      </StyledForm>
    </FormContainer>
  )
}

export default GetNotifiedFormHomePage
