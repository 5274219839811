/* global fbq */
import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import axios from "axios"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import SignupForm from "../Core/Footer/SignupForm"
import GetNotifiedForm from "../Forms/GetNotifiedFormHomePage"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row } from "styled-bootstrap-grid"
import { navigate } from "gatsby"
import { hashEmail } from "src/services/util/hash"

const Icon = styled.img`
  position: absolute;
  width: 100px;
  top: 8em;
  right: 10%;
  width: 150px;

  @media (max-width: ${breakpoints.lg}) {
    width: 85px;
  }
  @media (max-width: ${breakpoints.md}) {
    display: none;
    ${
      "" /* top: 8%;
    right: 5%;
    width: 100px; */
    }
  }
  @media (min-width: 1921px) {
    display: none;
  }
`
const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const OnClickButton = styled.button`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: calc(0.7vw + 1rem);

  line-height: 150%;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss13" on, "ss10" on, "ss09" on, "ordn" on;

  color: #ffffff;
  background-color: transparent;
  @media (max-width: ${breakpoints.md}) {
    font-size: 1.4rem;
  }
`
const Container = styled.div`
  background: ${(props) =>
    props.backgroundColor ? colors.royalBlue : "#319AD5"};
  color: black;
  ${"" /* padding-bottom: 20px; */}
  border-bottom: 1px solid ${colors.white};
  
  position: relative;
height: auto;
padding-top: 3em;
padding-bottom 3em;
  @media (min-width: ${breakpoints.md}) {
    ${"" /* padding-bottom: 10px; */}
  }
  `

const InnerContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`

const ButtonContainer = styled.div`
  margin-left: auto;
  width: fit-content;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;

    a {
      display: block;
      text-align: center;
    }
  }
`

const Arrow = styled.div`
  .arrow {
    width: 120px;
  }

  .line {
    margin-top: 14px;
    width: 90px;
    background: blue;
    height: 10px;
    float: left;
  }

  .point {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid blue;
    float: right;
  }
`

const Div = styled.div`
  ${"" /* padding-top: 25px; */}
  display: block;
  margin: auto;
  width: 50%;
  text-align: center;
  color: white;
  line-height: 35px;
  @media (min-width: 1921px) {
    width: auto;
  }
  @media (max-width: ${breakpoints.md}) {
  }
`

const H2 = styled.h2`
  padding-top: 25px;
  display: block;
  margin: auto;
  width: 50%;
  text-align: center;
  color: ${(props) =>
    props.initial
      ? "white"
      : props.backgroundColor
      ? "#319AD5"
      : colors.royalBlue};
  line-height: 100%;
  @media (min-width: 1921px) {
    width: auto;
  }
  @media (max-width: ${breakpoints.lg}) {
    line-height: 38px;
  }

  @media (max-width: ${breakpoints.md}) {
    line-height: 35px;
    width: 80%;
    padding-top: 0px;
    text-transform: uppercase;
  }
`

const H3 = styled.h2`
  display: block;
  padding-top: 15px;
  margin: auto;
  width: 50%;
  text-align: center;
  font-size: calc(0.7vw + 1rem);
  color: white;
  line-height: 100%;
  font-weight: 700;
  @media (max-width: ${breakpoints.md}) {
    width: 70%;
    font-size: 16px;
  }
`

const Input = styled.input`
  font-weight: 400;

  line-height: 142%;

  height: 50px;
  font-size: calc(0.1vw + 1rem);
  color: #07408a;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  text-align: left;
  border: 2px solid #c4c4c4;
  border-radius: 100px;

  padding-left: 2rem;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #319ad5;
    opacity: 0.8; /* Firefox */
    @media (max-width: ${breakpoints.md}) {
      font-size: 1rem;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    ${"" /* font-size: 35px; */}
    ${"" /* width:  50%; */}
    width: 80%;
    height: 50px;
  }
`

const DeliveryZone = (props) => {
  const [inZone, setInZone] = useState(false)
  const [nearZone, setNearZone] = useState(false)
  const [notInZone, setNotInZone] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const LA = [
    90001, 90002, 90003, 90004, 90005, 90006, 90007, 90008, 90009, 90010, 90011,
    90012, 90013, 90014, 90015, 90016, 90017, 90018, 90019, 90020, 90021, 90022,
    90023, 90024, 90025, 90026, 90027, 90028, 90029, 90030, 90031, 90032, 90033,
    90034, 90035, 90036, 90037, 90038, 90039, 90040, 90041, 90042, 90043, 90044,
    90045, 90046, 90047, 90048, 90049, 90050, 90051, 90052, 90053, 90054, 90055,
    90056, 90057, 90058, 90059, 90060, 90061, 90062, 90063, 90064, 90065, 90066,
    90067, 90068, 90069, 90070, 90071, 90072, 90073, 90074, 90075, 90076, 90077,
    90078, 90079, 90080, 90081, 90082, 90083, 90084, 90086, 90087, 90088, 90089,
    90090, 90091, 90093, 90094, 90095, 90096, 90099, 90189, 90201, 90202, 90209,
    90210, 90211, 90212, 90213, 90220, 90221, 90222, 90223, 90224, 90230, 90231,
    90232, 90233, 90239, 90240, 90241, 90242, 90245, 90247, 90248, 90249, 90250,
    90251, 90254, 90255, 90260, 90261, 90262, 90263, 90265, 90266, 90267, 90270,
    90272, 90274, 90275, 90277, 90278, 90280, 90290, 90291, 90292, 90293, 90294,
    90295, 90296, 90301, 90302, 90303, 90304, 90305, 90306, 90307, 90308, 90309,
    90310, 90311, 90312, 90401, 90402, 90403, 90404, 90405, 90406, 90407, 90408,
    90409, 90410, 90411, 90501, 90502, 90503, 90504, 90505, 90506, 90506, 90601,
    90602, 90603, 90604, 90605, 90606, 90606, 90607, 90608, 90609, 90610, 90610,
    90623, 90630, 90631, 90633, 90637, 90638, 90639, 90640, 90650, 90651, 90652,
    90660, 90661, 90662, 90670, 90671, 90701, 90703, 90706, 90707, 90710, 90712,
    90713, 90714, 90715, 90716, 90717, 90723, 90731, 90732, 90744, 90745, 90746,
    90747, 90748, 90755, 90802, 90803, 90804, 90805, 90806, 90807, 90808, 90809,
    90810, 90813, 90814, 90815, 90822, 90831, 90833, 90840, 90844, 90846, 90848,
    90853, 90895, 91001, 91003, 91006, 91007, 91008, 91009, 91010, 91011, 91012,
    91016, 91017, 91020, 91021, 91023, 91024, 91025, 91030, 91031, 91040, 91041,
    91043, 91046, 91066, 91077, 91101, 91102, 91103, 91104, 91105, 91106, 91107,
    91108, 91109, 91110, 91114, 91115, 91116, 91117, 91118, 91121, 91123, 91124,
    91125, 91126, 91129, 91182, 91184, 91185, 91188, 91189, 91199, 91201, 91202,
    91203, 91204, 91205, 91206, 91207, 91208, 91209, 91210, 91214, 91221, 91222,
    91224, 91225, 91226, 91301, 91302, 91303, 91304, 91305, 91306, 91307, 91308,
    91309, 91311, 91313, 91316, 91324, 91325, 91326, 91327, 91328, 91329, 91330,
    91331, 91333, 91334, 91335, 91337, 91340, 91341, 91342, 91343, 91344, 91345,
    91346, 91352, 91353, 91356, 91357, 91361, 91362, 91364, 91367, 91371, 91392,
    91393, 91394, 91395, 91396, 91401, 91402, 91403, 91404, 91405, 91406, 91407,
    91408, 91409, 91410, 91411, 91412, 91413, 91416, 91423, 91426, 91436, 91470,
    91482, 91495, 91496, 91499, 91501, 91502, 91503, 91504, 91505, 91506, 91507,
    91508, 91510, 91521, 91522, 91523, 91526, 91601, 91602, 91603, 91604, 91605,
    91606, 91607, 91608, 91609, 91610, 91611, 91612, 91614, 91615, 91616, 91617,
    91618, 91706, 91709, 91710, 91714, 91715, 91716, 91722, 91723, 91724, 91731,
    91732, 91733, 91734, 91735, 91740, 91741, 91744, 91745, 91746, 91747, 91748,
    91749, 91754, 91755, 91756, 91765, 91766, 91767, 91768, 91769, 91770, 91771,
    91772, 91773, 91775, 91776, 91778, 91780, 91788, 91789, 91790, 91791, 91792,
    91796, 91799, 91801, 91802, 91803, 91804, 91896, 91899, 92821, 92823,
  ]
  const palmDesert = [92210, 92211, 92260, 92270]
  const SD = [
    91902, 91908, 91909, 91910, 91911, 91912, 91913, 91914, 91915, 91921, 91932,
    91933, 91935, 91941, 91942, 91943, 91944, 91945, 91946, 91950, 91951, 91976,
    91977, 91978, 91979, 92003, 92007, 92008, 92009, 92010, 92011, 92013, 92014,
    92018, 92020, 92021, 92022, 92023, 92024, 92025, 92026, 92027, 92028, 92029,
    92030, 92033, 92037, 92038, 92039, 92040, 92046, 92049, 92051, 92052, 92054,
    92056, 92064, 92067, 92069, 92071, 92072, 92074, 92075, 92078, 92079, 92081,
    92082, 92083, 92084, 92085, 92091, 92092, 92093, 92096, 92101, 92102, 92103,
    92104, 92105, 92106, 92107, 92108, 92109, 92110, 92111, 92112, 92113, 92114,
    92115, 92116, 92117, 92118, 92119, 92120, 92121, 92122, 92123, 92124, 92126,
    92127, 92128, 92129, 92130, 92131, 92132, 92134, 92135, 92136, 92137, 92138,
    92139, 92140, 92142, 92143, 92145, 92147, 92149, 92150, 92152, 92153, 92154,
    92155, 92158, 92159, 92160, 92161, 92163, 92165, 92166, 92167, 92168, 92169,
    92170, 92171, 92172, 92173, 92174, 92175, 92176, 92177, 92178, 92179, 92182,
    92186, 92187, 92191, 92192, 92193, 92195, 92196, 92197, 92198, 92199,
  ]
  const orangeCounty = [
    90620, 90621, 90622, 90624, 90680, 90720, 90740, 90742, 92602, 92603, 92604,
    92606, 92610, 92612, 92614, 92616, 92617, 92618, 92619, 92620, 92624, 92625,
    92626, 92627, 92628, 92629, 92630, 92637, 92646, 92647, 92648, 92649, 92651,
    92653, 92655, 92656, 92657, 92660, 92661, 92662, 92663, 92672, 92673, 92675,
    92677, 92678, 92679, 92683, 92688, 92691, 92692, 92694, 92697, 92701, 92703,
    92704, 92705, 92706, 92707, 92708, 92710, 92725, 92780, 92782, 92801, 92802,
    92803, 92804, 92805, 92806, 92807, 92808, 92809, 92811, 92812, 92814, 92815,
    92816, 92817, 92822, 92825, 92831, 92832, 92833, 92834, 92835, 92836, 92837,
    92838, 92840, 92841, 92843, 92844, 92845, 92850, 92861, 92863, 92865, 92866,
    92867, 92868, 92869, 92870, 92871, 92886, 92887,
  ]
  const allowedZones = LA.concat(SD).concat(orangeCounty)

 
  const longBeach = [
    90713, 90731, 90740, 90755, 90802, 90803, 90804, 90805, 90806, 90807, 90808,
    90810, 90813, 90814, 90815, 90822, 90831,
  ]
  const bayArea = [
    93635, 94002, 94005, 94010, 94014, 94015, 94018, 94019, 94020, 94021, 94022,
    94024, 94025, 94027, 94028, 94030, 94080, 94035, 94108, 94037, 94038, 94040,
    94111, 94112, 94114, 94041, 94043, 94044, 94060, 94061, 94062, 94063, 94065,
    94066, 94070, 94074, 94085, 94086, 94087, 94089, 94102, 94105, 94107, 94103,
    94104, 94109, 94115, 94110, 94116, 94402, 94403, 94404, 94501, 94117, 94118,
    94121, 94122, 94123, 94124, 94127, 94128, 94129, 94130, 94131, 94132, 94133,
    94134, 94548, 94143, 94549, 94550, 94551, 94552, 94553, 94555, 94556, 94558,
    94559, 94560, 94561, 94563, 94564, 94565, 94566, 94567, 94568, 94569, 94571,
    94158, 94301, 94303, 94304, 94572, 94574, 94305, 94306, 94401, 94502, 94503,
    94505, 94506, 94507, 94508, 94509, 94510, 94511, 94512, 94513, 94514, 94515,
    94517, 94518, 94519, 94520, 94521, 94523, 94525, 94526, 94528, 94530, 94531,
    94533, 94534, 94576, 94577, 94535, 94536, 94538, 94539, 94541, 94542, 94544,
    94545, 94546, 94578, 94579, 94580, 94582, 94583, 94585, 94586, 94587, 94588,
    94589, 94590, 94591, 94592, 94547, 94595, 94596, 94597, 94598, 94599, 94601,
    94602, 94603, 94605, 94606, 94607, 94608, 95023, 95030, 95032, 95033, 95035,
    95037, 95045, 95046, 95050, 95051, 94609, 94610, 94611, 94612, 95053, 94613,
    94618, 94619, 94621, 94702, 95110, 95111, 94703, 95112, 94704, 94705, 95135,
    95136, 95138, 94706, 94707, 94708, 95192, 95206, 95219, 95242, 95304, 95322,
    95360, 95363, 95377, 95391, 95401, 95403, 95404, 94709, 94710, 94720, 94801,
    94803, 94804, 94805, 94806, 94901, 94903, 94904, 94920, 95405, 95407, 95409,
    95412, 95444, 95445, 95446, 95448, 95449, 95450, 94922, 94923, 94924, 94925,
    94928, 94929, 94930, 94931, 94933, 94937, 95426, 95436, 95452, 95457, 95461,
    95462, 95465, 94938, 94939, 94940, 94941, 94945, 94946, 94947, 95471, 95472,
    95476, 95492, 95497, 95606, 95607, 95612, 95615, 95616, 95618, 94949, 94951,
    94952, 94954, 94956, 94957, 94960, 94963, 95620, 94964, 94965, 94970, 94971,
    95641, 95679, 95687, 95688, 95690, 95694, 94972, 94973, 95439, 95441, 95442,
    95002, 95006, 95008, 95013, 95014, 95020, 95054, 95060, 95070, 95076, 95113,
    95116, 95117, 95118, 95119, 95120, 95121, 95122, 95123, 95124, 95125, 95126,
    95127, 95128, 95129, 95130, 95131, 95132, 95133, 95134, 95139, 95140, 95141,
    95148, 95419, 95421, 95425,
  ]
  const santaBarbara = [
    93013, 93014, 93067, 93101, 93102, 93103, 93105, 93106, 93107, 93108, 93108,
    93109, 93110, 93110, 93111, 93111, 93116, 93116, 93117, 93117, 93117, 93117,
    93118, 93118, 93120, 93121, 93130, 93140, 93150, 93150, 93160, 93190, 93254,
    93254, 93427, 93429, 93434, 93436, 93437, 93437, 93438, 93440, 93441, 93454,
    93455, 93455, 93456, 93457, 93457, 93458, 93460, 93463, 93463, 93464,
  ]
  const inlandEmpire = [
    92501, 92503, 92504, 92505, 92506, 92507, 92508, 92509, 92879, 92880, 92881,
    92882, 92883, 92860, 91708, 91710, 91710, 91758, 91761, 91762, 91764, 91763,
    91711, 92590, 92591, 92592, 92562, 92563, 92530, 92532,
  ]
  async function subscribe(url, body, nav) {
    await axios.post(url, body)
    if (inZone && nav) {
      navigate("/shop")
    } else {
      setNearZone(false)
      let message = document.getElementById("message")
      if (message) {
        message.innerHTML = "We Look Forward To Serving You Soon!"
      }
      let TheInputElm = document.getElementById("TheInput")
      if (TheInputElm) {
        TheInputElm.value = ""
      }
    }
  }

  const buttonStyleObj = {
    borderColor: "#07408A",
    border: "none",
    // color: "#07408A",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    textDecoration: "underline",
  }
  function handleSubmit(e) {
    e.preventDefault()
    let TheInputElm = document.querySelector("#TheInput")
    let TheInput = TheInputElm?.value
    let message = ""
    let hiddenZipElm = document.querySelector("#hiddenZipCode")
    let hiddenZip = hiddenZipElm?.value
    console.log(hiddenZip)
    console.log(orangeCounty.includes(parseInt(hiddenZip)))
    let PhoneInput = document.querySelector("#PhoneInput")
    let phoneNumber = PhoneInput?.value

    if (
      phoneNumber &&
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        phoneNumber
      )
    ) {
      phoneNumber = phoneNumber.replace(/\D+/g, "")
      if (phoneNumber.startsWith("1")) {
        phoneNumber = "+" + phoneNumber
      } else {
        phoneNumber = "+1" + phoneNumber
      }
    } else {
      phoneNumber = undefined
    }

    if ((nearZone || notInZone) && TheInput) {
      let market_signup
      if (LA.includes(parseInt(hiddenZip))) {
        market_signup = "LA"
      } else if (SD.includes(parseInt(hiddenZip))) {
        market_signup = "SD"
      } else if (inlandEmpire.includes(parseInt(hiddenZip))) {
        market_signup = "IE"
      } else if (santaBarbara.includes(parseInt(hiddenZip))) {
        market_signup = "SB"
      } else if (longBeach.includes(parseInt(hiddenZip))) {
        market_signup = "LB"
      } else if (bayArea.includes(parseInt(hiddenZip))) {
        market_signup = "Bay Area"
      } else if (palmDesert.includes(parseInt(hiddenZip))) {
        market_signup = "Palm Desert"
      } else {
        market_signup = "Other"
      }
      const url = "/.netlify/functions/klaviyoSubscribe"
      let data = {
        email: TheInput,
        referred_from: "Zip Code",
        "Zip Code": hiddenZip,
        $market_signup: market_signup,
        listId: "QRSEQM",
      }
      // if (orangeCounty.includes(parseInt(hiddenZip)))
      // {
      //   data.$market_latest = "OC";
      // }
      subscribe(url, data, false)
      try {
        if (typeof window.gtag !== "undefined") {
          let gtag_data = {
            method: "zip_code_email_signup",
            send_to: "G-LZ50YRTV2Y",
          }

          window.gtag("event", "sign_up", gtag_data)
          let old_gtag_data = {
            event_category: "form_submit",
            event_label: "zip code lookup",
            // email: data.email,
            zip: data["Zip Code"],
            send_to: "UA-185335499-1",
          }
          window.gtag("event", "form_submit", old_gtag_data)
        }
      } catch (e) {
        console.log("whoopsie daisy")
      }

      try {
        fbq("track", "Lead", {
          content_name: "Email Signup",
          content_category: "Newsletter",
          user_data: {
            em: hashEmail(data.email),
          },
        })
      } catch (e) {
        console.log(e)
      }
      setInZone(false)
      setNearZone(false)
      setNotInZone(false)
      setSubmitted(true)
      document.querySelector("#message").innerHTML = ""
      //SUBMIT EMAIL
    } else if (inZone && TheInput) {
      const url = "/.netlify/functions/klaviyoSubscribe"
      let market_signup
      if (LA.includes(parseInt(hiddenZip))) {
        market_signup = "LA"
      } else if (SD.includes(parseInt(hiddenZip))) {
        market_signup = "SD"
      } else if (inlandEmpire.includes(parseInt(hiddenZip))) {
        market_signup = "IE"
      } else if (santaBarbara.includes(parseInt(hiddenZip))) {
        market_signup = "SB"
      } else if (longBeach.includes(parseInt(hiddenZip))) {
        market_signup = "LB"
      } else if (bayArea.includes(parseInt(hiddenZip))) {
        market_signup = "Bay Area"
      } else if (palmDesert.includes(parseInt(hiddenZip))) {
        market_signup = "Palm Desert"
      } else {
        market_signup = "Other"
      }
      let data = {
        email: TheInput,
        referred_from: "Zip Code",
        "Zip Code": hiddenZip,
        $market_signup: market_signup,
        listId: "QRSEQM",
      }
      if (phoneNumber) {
        data.phone_number = phoneNumber
        data.sms_consent = true
        document.querySelector("#message").innerHTML = ""
      }
      // else {
      //   document.querySelector("#message").innerHTML = "please enter a valid phone number";
      //   return;
      // }
      subscribe(url, data, false)
      try {
        let old_gtag_data = {
          event_category: "form_submit",
          event_label: "zip code lookup",
          // email: data.email,
          zip: data["Zip Code"],
          send_to: "UA-185335499-1",
        }

        let gtag_data = {
          method: "zip_code_phone_signup",
          send_to: "G-LZ50YRTV2Y",
        }
        if (typeof window.gtag !== "undefined") {
          window.gtag("event", "sign_up", gtag_data)
          window.gtag("event", "form_submit", old_gtag_data)
        }
      } catch (e) {
        console.log("whoopsie daisy")
      }

      try {
        fbq("track", "Lead", {
          content_name: "Email Signup",
          content_category: "Newsletter",
          email: data.email,
        })
      } catch (e) {
        console.log(e)
      }
      if (phoneNumber) {
        data.listId = "Vcv8RW" //subscribe to sms list as well
        subscribe(url, data, true)
      }

      setInZone(false)
      setNearZone(false)
      setNotInZone(false)
      setSubmitted(true)
      document.querySelector("#message").innerHTML = ""
      //SUBMIT EMAIL AND NAVIGATE TO SHOP
    } else if (TheInput) {
      if (allowedZones.includes(parseInt(TheInput))) {
        // message = "You're in our delivery zone! <a href='/shop'> Click here to shop </a>";
        setInZone(true)
        setNearZone(false)
        setNotInZone(false)
        hiddenZipElm.value = TheInput
        TheInputElm.value = ""
      } else if (
        orangeCounty.includes(parseInt(TheInput)) ||
        longBeach.includes(parseInt(TheInput)) ||
        bayArea.includes(parseInt(TheInput)) ||
        inlandEmpire.includes(parseInt(TheInput)) ||
        santaBarbara.includes(parseInt(TheInput))
      ) {
        message = "We're looking to expand to your area soon!"
        setInZone(false)
        setNearZone(true)
        setNotInZone(false)
        hiddenZipElm.value = TheInput
        TheInputElm.value = ""
      } else {
        message = "You're not in our delivery zone."
        setInZone(false)
        setNearZone(false)
        setNotInZone(true)
        hiddenZipElm.value = TheInput
        TheInputElm.value = ""
      }
    } else {
      message = "You're not in our delivery zone."
      setInZone(false)
      setNearZone(false)
      setNotInZone(true)
    }
    // document.querySelector("#message").innerHTML = message;
  }

  return (
    <Container backgroundColor={props.backgroundColor}>
      {props &&
      props.comingSoonImage &&
      props.inTheZoneImage &&
      props.notInTheZoneImage &&
      props.defaultImage ? (
        <InnerContainer>
          {/* <IconWrapper> */}
          <Icon src={props.icon.file.url} />
          {/* </IconWrapper> */}

          <form onSubmit={handleSubmit}>
            <H2
              backgroundColor={props.backgroundColor}
              initial={!inZone && !notInZone && !nearZone}
            >
              {notInZone
                ? "SORRY WE DON'T DELIVER HERE"
                : inZone
                ? "YOU'RE IN OUR DELIVERY AREA!"
                : nearZone
                ? "NOT IN YOUR AREA...YET"
                : submitted
                ? "THANK YOU!"
                : props.heading}
            </H2>
            <H3>
              {notInZone ? (
                <>
                  But you can still join our table! Follow us on Instagram at{" "}
                  <a
                    style={{ color: colors.royalBlue }}
                    href="https://www.instagram.com/eatigc"
                    target="_blank"
                  >
                    @eatigc
                  </a>{" "}
                  for recipes and other fun updates.
                </>
              ) : inZone ? (
                "Hooray! We can’t wait for you to taste In Good Company. Enter your email for 10% off. Add your phone number for an additional 5% off!"
              ) : nearZone ? (
                "We’re headed your way soon. Enter your email to be the first to know when we’re delivering to you. "
              ) : submitted ? (
                ""
              ) : (
                props.subHeading
              )}
            </H3>
            {/* <br /> */}
               <i class="fa fa-envelope icon">              </i>
            <Input
              id={"hiddenZipCode"}
              name={"hiddenZipCode"}
              style={{ display: "none" }}
            ></Input>
            {!submitted ? (
              <Input
                id="TheInput"
                placeholder={
                  nearZone || inZone || notInZone ? "Email " : "Zip Code"
                }
              ></Input>
            ) : (
              ""
            )}
            {inZone ? (
              <Input
                style={{ marginTop: "1vw" }}
                id="PhoneInput"
                placeholder={"Phone Number"}
              />
            ) : (
              ""
            )}
            <br />
            {!submitted ? (
              <OnClickButton style={buttonStyleObj}>
                {!inZone && !nearZone && !notInZone
                  ? "Check Availability"
                  : nearZone || inZone || notInZone
                  ? "Subscribe"
                  : ""}
              </OnClickButton>
            ) : (
              ""
            )}
            <br />
            <Div style={{ width: "80%" }} id="message"></Div>
          </form>
          <Div>
            {/* { nearZone ? <GetNotifiedForm responseText={"blabla"} /> : ""} */}
          </Div>
        </InnerContainer>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Div>See If We Deliver To You:</Div>

            <br />
            <Input id="zipInput" placeholder="ZIP"></Input>
            <br />

            <OnClickButton style={buttonStyleObj}>
              Check Availability
            </OnClickButton>
            <br />
            <Div id="message"></Div>
          </form>
          {/* <Div>
                  { nearZone ? <GetNotifiedForm responseText={"blabla"} /> : ""}
              </Div>   */}
        </>
      )}
    </Container>
  )
}

export default DeliveryZone
