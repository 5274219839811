import colors from "./colors"
import Arrow from "src/resources/img/svg/arrow.svg"

const selectStyles = (props) => {
  return {
    control: (styles) => ({
      ...styles,
      borderRadius: "0",
      border: `1px solid ${colors.midGrey}`,
      padding: "10px",
      boxShadow: "none",
      cursor: "pointer",
      minHeight: "70px",
      fontSize: "1rem",
      background: "transparent",
      ":hover": {
        borderColor: colors.midGrey,
      },
    }),
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      color: colors.black,
      fontSize: "1rem",
      opacity: state.isDisabled ? 0.3 : 1,
      background: state.isSelected
        ? "rgba(49,154,213, 0.4)"
        : state.isFocused
        ? colors.white
        : colors.white,
      ":hover": {
        background: state.isSelected
          ? "rgba(49,154,213, 0.4)"
          : state.isDisabled
          ? "transparent"
          : "rgba(49,154,213, 0.2)",
      },
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: 0,
      zIndex: 3
    }),
    placeholder: (styles) => ({
      ...styles,
      textTransform: "uppercase",
      fontSize: "1rem",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      background: "url(" + Arrow + ") no-repeat",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "0.1s",
      backgroundSize: "24px 24px",
      backgroundPosition: "center",
      width: "30px",
      svg: {
        display: "none",
      },
    }),
  }
}

export { selectStyles }
